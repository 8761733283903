{
  "name": "iotzen_platform",
  "version": "9.0.8-uat.1",
  "private": true,
  "license": "LICENSED",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration local",
    "host-local": "ng serve --configuration=local --host 0.0.0.0",
    "build": "ng build --configuration production ",
    "build-sm": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration production --stats-json  --source-map",
    "run-dev": "node --max_old_space_size=4048 ./node_modules/@angular/cli/bin/ng serve --configuration local",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "analyse": "webpack-bundle-analyzer dist/stats.json",
    "build-serve": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve",
    "explore": "npm run build-sm; source-map-explorer dist/**/*.js",
    "update": "ng update @angular/core@18 @angular/cli@18 --force",
    "cf": "ng generate @angular/core:control-flow",
    "standlonw": "ng generate @angular/core:standalone",
    "state-migration": "ng update @ngrx/store@18",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org zendynamix --project iotzen-ui ./dist && sentry-cli sourcemaps upload --org zendynamix --project iotzen-ui ./dist"
  },
  "dependencies": {
    "@angular/animations": "^18.1.2",
    "@angular/cdk": "^18.1.2",
    "@angular/common": "^18.1.2",
    "@angular/compiler": "^18.1.2",
    "@angular/core": "^18.1.2",
    "@angular/forms": "^18.1.2",
    "@angular/localize": "^18.1.2",
    "@angular/material": "^18.1.2",
    "@angular/material-moment-adapter": "^18.1.2",
    "@angular/platform-browser": "^18.1.2",
    "@angular/platform-browser-dynamic": "^18.1.2",
    "@angular/pwa": "^18.1.2",
    "@angular/router": "^18.1.2",
    "@angular/service-worker": "^18.1.2",
    "@asymmetrik/ngx-leaflet-markercluster": "^18.0.0",
    "@bluehalo/ngx-leaflet": "^18.0.2",
    "@casl/ability": "^6.7.1",
    "@casl/angular": "^8.2.7",
    "@ngrx/effects": "^18.0.1",
    "@ngrx/store": "^18.0.1",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/angular": "^8.21.0",
    "ang-jsoneditor": "^3.1.1",
    "angular-calendar": "^0.31.1",
    "buffer": "^6.0.3",
    "echarts": "^5.5.1",
    "file-saver": "^2.0.5",
    "firebase": "^10.12.4",
    "font-awesome": "^4.7.0",
    "leaflet": "^1.9.4",
    "leaflet.gridlayer.googlemutant": "^0.14.1",
    "leaflet.markercluster": "^1.5.3",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.45",
    "ngx-echarts": "^18.0.0",
    "ngx-webcam": "^0.4.1",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.6",
    "print-js": "^1.6.0",
    "quill": "^2.0.2",
    "razorpay": "^2.9.4",
    "rxjs": "^7.8.1",
    "socket.io-client": "^4.7.5",
    "tslib": "^2.6.3",
    "xlsx": "^0.18.5",
    "chart.js": "^4.4.3",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.2",
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/cli": "^18.1.2",
    "@angular/compiler-cli": "^18.1.2",
    "@angular/language-service": "^18.1.2",
    "@ngrx/store-devtools": "^18.0.1",
    "@popperjs/core": "^2.11.8",
    "@sentry/cli": "^2.33.0",
    "@types/echarts": "^4.9.22",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/leaflet": "^1.9.12",
    "@types/leaflet.markercluster": "^1.5.4",
    "@types/lodash": "^4.17.7",
    "@types/moment": "^2.13.0",
    "@types/node": "^20.14.12",
    "@types/socket.io-client": "^3.0.0",
    "@typescript-eslint/eslint-plugin": "^7.16.1",
    "@typescript-eslint/parser": "^7.16.1",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "protractor": "~7.0.0",
    "source-map-explorer": "^2.5.3",
    "ts-node": "~10.9.2",
    "typescript": "~5.4.5"
  }
}
